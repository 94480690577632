import React from "react"

import Layout from "../components/Layout"
import Page from "../components/Layout/page"
import ForthcomingAuctionsOther from "../components/Content/ForthcomingAuctionsOther"

const UpcomingAuctions = props => (
  <Layout location={props.location}>
    <ForthcomingAuctionsOther />
    <Page id="2" />
  </Layout>
)

export default UpcomingAuctions
