import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import Loader from "../../Loader"
import Message from "../../Message"
import Icon from "../../Icon"

import "./index.scss"

const auctionsAPI = process.env.API_AUCTIONS

const ForthcomingAuctionsOther = () => {
  const [auctions, setAuctions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState({})

  useEffect(() => {
    Axios.get(`${auctionsAPI}/?page=1&per-page=100`)
      .then((response) => {
        setAuctions(response.data.auctions)
        setIsLoading(false)
      })
      .catch((error) => {
        setMessage({
          type: "danger",
          text: error.response.data.message,
        })
        setIsLoading(false)
      })
  }, [])

  const auctionsList = auctions.map((auction) => (
    <div className="row auction" key={auction.id}>
      <div className="col-1 text-center">
        <span className="auction-icon">
          <Icon name="gavel" />
        </span>
      </div>
      <div className="col-11 text-left">
        <Link to={`/auction/${auction.id}`}>{auction.title}</Link>
        <br />
        {auction.endDate <= auction.startDate ? (
          <span className="auction-date">
            <Moment
              format="dddd Do MMMM @ hh:mma"
              date={auction.startDate}
              unix
              local
              tz="Australia/Sydney"
            />
          </span>
        ) : (
          <>
            <span className="auction-date">
              Starts:{" "}
              <Moment
                format="dddd Do MMMM @ hh:mma"
                date={auction.startDate}
                unix
                local
                tz="Australia/Sydney"
              />
            </span>
            <br />
            <span className="auction-date">
              Ends:{" "}
              <Moment
                format="dddd Do MMMM @ hh:mma"
                date={auction.endDate}
                unix
                local
                tz="Australia/Sydney"
              />
            </span>
          </>
        )}
      </div>
    </div>
  ))

  return (
    <>
      <Message type={message.type} message={message.text} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <h2 className="forthcoming-title">Forthcoming Auctions</h2>
            </div>
            <div className="col-12 auction-list">{auctionsList}</div>
          </div>
        </>
      )}
    </>
  )
}

export default ForthcomingAuctionsOther
